import { Box } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { VideoBox } from 'components/common/VideoBox/VideoBox';
import { Z_INDEX } from 'styles/zIndex';

type ArticleVideoProps = {
  rawVideoUrl: string;
  thumbnailUrl?: string;
  description?: string;
  isEmbedded?: boolean;
};

export const ArticleVideo = ({
  rawVideoUrl,
  thumbnailUrl,
  description,
  isEmbedded = false,
}: ArticleVideoProps): ReactElement => {
  // As there are two yt embed formats, we need to check for both e.g.: https://www.youtube.com/watch?v=sT1KpjKbsTk vs https://youtu.be/Gmh4jMP0s7I
  const getYoutubeId = (url: string): string => {
    return url.split('youtu.be/')[1] ?? url.split('v=')[1];
  };

  const getYoutubeEmbedUrl = (url: string): string => {
    const ytId = getYoutubeId(url);
    return `https://www.youtube.com/embed/${ytId}`;
  };

  const videoUrl = isEmbedded ? getYoutubeEmbedUrl(rawVideoUrl) : rawVideoUrl;
  const videoUrlWithNHRecommendations = `${videoUrl}?rel=0`;

  return (
    <Box
      as="section"
      height="200px"
      background="transparent"
      cursor="pointer"
      display="grid"
      placeItems="center"
      zIndex={Z_INDEX.BASE}
      maxW="100%"
      overflow="hidden"
      my="25px"
    >
      <Box
        position="relative"
        border="1px solid grey.10"
        boxSize="175px"
        borderRadius="12px"
        overflow="hidden"
      >
        <VideoBox
          videoUrl={videoUrlWithNHRecommendations}
          isEmbedded={isEmbedded}
          videoDescription={description || 'An explanation video'}
          thumbnailUrl={thumbnailUrl}
        />
      </Box>
    </Box>
  );
};
